import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "react-lazy-load-image-component/src/effects/blur.css"

import mouseWheel from "../../images/mouse-wheel.gif"

const UsdaFinancing = () => (
  <Layout>
    <SEO
      title="USDA Financing"
      description="A USDA Home Loan is a mortgage loan offered to rural property owners by the United States Department of Agriculture."
    />
    <div className="container-fluid hero-section">
      <div className="row">
        <div className="col-md-5 vertical-align d-none-mobile">
          <div className="brandstamp">
            <h1>USDA Financing</h1>
            <p className=" pb-3 pt-3">
              A USDA Home Loan is a mortgage loan offered to rural property
              owners by the United States Department of Agriculture.
              <br />
            </p>
          </div>
        </div>
        <div className="col-md-7 uf-hero-img vertical-align">
          <div className="brandstamp-mobile text-white">
            <h1>USDA Financing</h1>
            <p className="mb-5">
              A USDA Home Loan is a mortgage loan offered to rural property
              owners by the United States Department of Agriculture.
              <br />
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="col-lg-12 text-center scroll-down">
      <img className="mouse-wheel" src={mouseWheel} alt="Scroll Down" />
    </div>

    <div className="container uf-wrapper mt-5">
      <div className="row flex-column-reverse flex-md-row">
        <div className="col-lg-8">
          <div className="apply-container">
            <ul>
              <li>30-year fixed</li>
              <li>No down payment required</li>
              <li>No cash reserves needed</li>
              <li>Closing costs may be financed on some transactions</li>
              <li>Seller contributions allowed</li>
              <li>Competitively low rates</li>
            </ul>
          </div>
          <div className="mt-5">
            <Link
              to="/loan-programs"
              className="btn-global-inverted mr-3 text-white"
              type="button"
            >
              Back to Loan Programs
            </Link>
          </div>
        </div>
        <div className="col-lg-4 text-white">
          <h4 style={{ color: "#0658ff" }} className="mt-3">
            More Loan Options
          </h4>
          <ul>
            <li>
              <Link to="/loan-programs/jumbo-loans">JUMBO Loans</Link>
            </li>
            <li>
              <Link to="/loan-programs/usda-financing">USDA Financing</Link>
            </li>
            <li>
              <Link to="/loan-programs/doctor-program">Doctor Program</Link>
            </li>
            <li>
              <Link to="/loan-programs/exclusive-high-balance-nationwide">
                Colorado High Balance
              </Link>
            </li>
            <li>
              <Link to="/loan-programs/fha-home-loans">FHA Home Loans</Link>
            </li>
            <li>
              <Link to="/loan-programs/va-home-loans">VA Home Loans</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div className="apply-section">
      <div className="protruded-gradient"></div>
      <div className="container">
        <div className="apply-container">
          <div className="row">
            <div className="col-md-10">
              <div>
                <h2 className="mb-2 mt-3">
                  Let's get started
                  <br />
                </h2>
                <p className="pb-4">
                  Getting pre-approved can be quick and easy. Start the process
                  today!
                  <br />
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <Link to="https://plus.preapp1003.com/Darlene-Franklin" className="btn-global w-100 mb-3" type="button">
                Apply Now
              </Link>
              <Link
                to="/loan-process"
                className="btn-global-inverted w-100 text-center"
                type="button"
                style={{ padding: "10px 20px" }}
              >
                Our Process
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default UsdaFinancing
